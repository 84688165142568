
import Vue from 'vue';
import DashboardCard from '@/components/cards/DashboardCard.vue';
import navigationItems from '@/router/navigationItems';
import StartOnboardingCard from '@/components/cards/StartOnboardingCard';

export default Vue.extend({
  components: {
    DashboardCard,
    StartOnboardingCard,
  },
  data() {
    return {
      ...navigationItems,
    };
  },
  computed: {
    showOnboardingMessage() {
      return this.$store.getters['user/showOnboardingMessage'];
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
});
