
import Vue from 'vue';
import DashboardCard from '@/components/cards/DashboardCard.vue';
import navigationItemsBusiness from '@/router/navigationItemsBusiness';
import EmptyCardPosition from '@/components/cards/EmptyCardPosition';

export default Vue.extend({
  components: {
    DashboardCard,
    EmptyCardPosition,
  },
  data() {
    return {
      ...navigationItemsBusiness,
    };
  },
  computed: {
    currentPosition() {
      return this.$store.state.business.positions.find(
        (position) =>
          position.id === this.$store.state.business.selectedPositionId
      );
    },
    isLoading() {
      return this.$store.state.business.loading;
    },
  },
  created() {
    window.scrollTo(0, 0);
  },
});
