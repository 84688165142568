<template lang="pug">

v-card.d-flex.flex-column.onboarding-card(:style="{ height: windowHeight + 'px' }")
  v-toolbar.flex-shrink-1.px-md-4.dialogToolbar(flat)
    .d-flex.flex-row.align-sm-center
      .headerIcon.d-flex.text-left.text-md-center.mb-0.pb-0.mr-4
        v-icon.headerIcon(size='40px', color='accent') {{currentStep === 1 ? "$conditions" : "$offerings" }}
      div
        h2 
          | {{currentStep === 1 ? "Ich suche" : "Ich biete"}}
          span.ml-2(style="color: grey; font-weight: 400") {{ currentStep }}/{{ 8  }}
    v-spacer 
    v-toolbar-items
      v-btn(icon, color="accent" @click='cancelOnboarding')
        v-icon mdi-close
  v-card-text.pt-8.px-0.px-md-4.flex-grow-1.pb-12#layout-scroll-container(style="overflow-y: auto" )
      .profileQuestionnaire.scroll-wrapper.pb-8.pt-4.full-height.mx-auto.d-flex
        div.full-width
          v-toolbar-title
            h2.text-center.mb-2(style="color: black") {{getTitle(currentStep)}}
          hr.accentHr
          //- Content Forms
          .pt-4.px-4(v-if='currentStep == 1')
            v-form(lazy-validation :ref="forms[0].ref" v-model="forms[0].valid" :key="forms[0].formKey")
              UpdateConditionsForm(v-model="currentUser.conditions")
          .px-4.pt-10(v-if='currentStep == 2')
            v-form( lazy-validation :ref="forms[1].ref" v-model="forms[1].valid" :key="forms[1].formKey") 
              UpdatePersonalDataForm( v-model="currentUser")
          v-form(v-if='currentStep == 3' lazy-validation :ref="forms[2].ref" v-model="forms[2].valid" :key="forms[2].formKey")
            UpdateEducationForm( v-model="currentUser.school" :type='EducationType.SCHOOL' :userType="UserType.CANDIDATE" :indexToUpdate="0" sectionTitle="Schule" :isAddDialog="true"  :validForm="forms[2].valid")
          v-form(v-if='currentStep == 4' lazy-validation :ref="forms[3].ref" v-model="forms[3].valid" :key="forms[3].formKey")
            UpdateEducationForm( v-model="currentUser.education" :type='EducationType.EDUCATION' :userType="UserType.CANDIDATE" :indexToUpdate="0" sectionTitle="Ausbildung / Studium" :isAddDialog="true" :validForm="forms[3].valid")
          .px-4
            v-form(v-if='currentStep == 5' lazy-validation :ref="forms[4].ref" v-model="forms[4].valid" :key="forms[4].formKey")
              UpdateExperienceForm( v-model="currentUser.practicalExperience" :type='ExperienceType.PRACTICAL_EXPERIENCE' :userType="UserType.CANDIDATE" :indexToUpdate="0" sectionTitle="Praxiserfahrung" :isAddDialog="true" )
          .px-4(v-if='currentStep == 6')
            v-form( lazy-validation :ref="forms[5].ref" v-model="forms[5].valid" :key="forms[5].formKey")
              UpdateExperienceForm( v-model="currentUser.otherExperience" :type='ExperienceType.OTHER_EXPERIENCE' :userType="UserType.CANDIDATE" :indexToUpdate="0" sectionTitle="Sonstige Erfahrung" :isAddDialog="true")
          v-form(v-if='currentStep == 7' lazy-validation :ref="forms[6].ref" v-model="forms[6].valid" :key="forms[6].formKey")
            UpdateSkillForm(:items="currentUser.languages" @itemsChanged="languageChanged" :type='SkillType.LANGUAGES' :userType="UserType.CANDIDATE" :formRef="$refs[forms[6].ref]")
          v-form(v-if='currentStep == 8' lazy-validation :ref="forms[7].ref" v-model="forms[7].valid" :key="forms[7].formKey")
            UpdateSkillForm(:items="currentUser.softwareSkills" @itemsChanged="softwareChanged" :type='SkillType.SOFTWARE' :userType="UserType.CANDIDATE" :formRef="$refs[forms[7].ref]")

  v-card-actions.justify-space-between.justify-md-center.dialogFooter     
    
    v-btn.text-center.mx-md-2(
        @click='prevStep',
        color="accent"
        depressed
        icon
        large
        :disabled="currentStep === 1"
    ) 
        v-icon(large) $leftArrow
    v-btn.text-center.mx-md-2(
        v-if='currentStep !== 8' 
        @click='skipStep',
        color="accent"
        depressed
        text
        large
        :disabled="!showSkipBtn"
        ) 
      span(style="font-size: 16px;") Überspringen
    v-btn.text-center.mx-md-2(
        v-if='currentStep !== 8' 
        :disabled='!forms[currentIndex].valid',
        @click='nextStep'
        color="accent"
        icon
        large
        depressed
    ) 
        v-icon(large) $rightArrow
    v-btn.text-center.accent( v-else depressed @click='nextStep')
      | Fertigstellen
</template>

<script>
import Vue from 'vue';

import { EducationType } from '@/types/education';
import { ExperienceType } from '@/types/experience';
import { cloneDeep } from 'lodash';
import { UserType } from '@/types/user';
import { SkillType } from '@/types/skill';
import UpdateConditionsForm from '@/components/candidate/lookingFor/conditions/UpdateConditionsForm.vue';
import UpdatePersonalDataForm from '@/components/candidate/offering/personal/UpdatePersonalDataForm.vue';
import UpdateEducationForm from '@/components/candidate/offering/educations/UpdateEducationForm.vue';
import UpdateExperienceForm from '@/components/candidate/offering/experiences/UpdateExperienceForm.vue';
import UpdateSkillForm from '@/components/candidate/offering/skills/UpdateSkillForm.vue';
import UpdateSkillDialog from '@/components/candidate/offering/skills/UpdateSkillDialog.vue';

export default Vue.extend({
  components: {
    UpdateConditionsForm,
    UpdatePersonalDataForm,
    UpdateEducationForm,
    UpdateExperienceForm,
    UpdateSkillForm,
    UpdateSkillDialog,
  },
  data() {
    return {
      UserType,
      stepCount: 8,
      EducationType,
      ExperienceType,
      forms: [
        {
          ref: 'conditionsForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'personalDataForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'schoolForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'educationForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'practicalExperienceForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'otherExperienceForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'languagesForm',
          valid: false,
          formKey: Math.random(),
        },
        {
          ref: 'softwareForm',
          valid: true,
          formKey: Math.random(),
        },
      ],
      closeOnboardingWithoutAlert: false,
      SkillType,
      currentUser: cloneDeep(this.$store.state.candidate.candidate),
      windowHeight: window.innerHeight,
    };
  },
  computed: {
    currentStep() {
      return Number(this.$route.query.step) || 1;
    },
    currentIndex() {
      return this.currentStep - 1;
    },
    mobile() {
      return this.$vuetify.breakpoint.xs;
    },
    showSkipBtn() {
      return (
        this.currentStep === 4 ||
        this.currentStep === 5 ||
        this.currentStep === 6
      );
    },
  },

  methods: {
    languageChanged(items) {
      this.currentUser.languages = items;
    },
    softwareChanged(items) {
      this.currentUser.softwareSkills = items;
    },
    getTitle() {
      switch (this.currentStep) {
        case 1:
          return 'Gewünschte Tätigkeit';
        case 2:
          return 'Persönliche Daten';
        case 3:
          return 'Schule';
        case 4:
          return 'Ausbildung / Studium';
        case 5:
          return 'Praxiserfahrung';
        case 6:
          return 'Sonstige Erfahrung';
        case 7:
          return 'Sprachen';
        case 8:
          return 'Software';
        default:
          return '';
      }
    },

    cancelOnboarding() {
      this.$router.push({ name: 'Offerings' });
    },
    hideDialog() {
      // update to trigger match creation for new users aborting onboarding
      this.$store.dispatch('candidate/updateCandidate', {
        updatedCandidate: {
          startedOnboarding: true,
        },
      });

      this.$emit('closeDialog');
    },
    async updateUserData() {
      if (this.currentStep === 2) {
        this.$store.dispatch('user/updateUser', {
          ...this.currentUser,
          startedOnboarding: true,
        });
      } else {
        let key = '';

        switch (this.currentStep) {
          case 1:
            key = 'conditions';
            break;
          case 3:
            key = 'school';
            break;
          case 4:
            key = 'education';
            break;
          case 5:
            key = 'practicalExperience';
            break;
          case 6:
            key = 'otherExperience';
            break;
          case 7:
            key = 'languages';
            break;
          case 8:
            key = 'softwareSkills';
            break;
          default:
            throw new Error('not implemented');
        }

        this.$store.dispatch('candidate/partialUpdate', {
          item: this.currentUser[key],
          key,
        });
      }
    },

    prevStep() {
      this.$router.back();
      this.scrollToTop();
    },

    async nextStep() {
      const currentRef = this.forms[this.currentIndex].ref;
      if (this.currentStep < 8 && !this.$refs[currentRef].validate()) {
        return;
      }

      await this.updateUserData();

      if (this.currentStep < 8) {
        this.$router.push({ query: { step: this.currentStep + 1 } });
      } else {
        this.closeOnboardingWithoutAlert = true;
        this.$router.push({ name: 'Matches' });
      }

      this.scrollToTop();
    },
    skipStep() {
      // remove data from current step if skipped
      switch (this.currentStep) {
        case 3:
          this.currentUser.school = [];
          break;
        case 4:
          this.currentUser.education = [];
          break;
        case 5:
          this.currentUser.practicalExperience = [];
          break;
        case 6:
          this.currentUser.otherExperience = [];
          break;
        case 7:
          this.currentUser.languages = cloneDeep(
            this.$store.state.candidate.candidate.languages ?? []
          );
          break;
        case 8:
          this.currentUser.softwareSkills = cloneDeep(
            this.$store.state.candidate.candidate.softwareSkills ?? []
          );
          break;
        default:
          break;
      }

      this.$router.push({ query: { step: this.currentStep + 1 } });
      this.scrollToTop();
    },
    checkLeavingModal(next) {
      this.$root.$confirm({
        title: 'Wirklich abbrechen?',
        message:
          'Möchtest Du wirklich abbrechen? Eventuell gehen ungespeicherte Änderungen verloren.',
        options: {
          btnDisagree: 'Nein, fortsetzen',
          btnAgree: 'Ja, abbrechen',
        },
        agree: () => next(),
        disagree: () => next(false),
      });
    },
    preventNavigation(event) {
      event.preventDefault();
      event.returnValue = '';
    },
    scrollToTop() {
      const layoutId = document.getElementById('layout-scroll-container');
      if (layoutId) {
        layoutId.scrollTop = 0;
      }
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
  created() {
    this.$store.dispatch('candidate/fetchCurrentCandidate');
    this.scrollToTop();
  },
  mounted() {
    this.scrollToTop();
  },
  beforeMount() {
    window.addEventListener('beforeunload', this.preventNavigation);
    window.addEventListener('resize', this.onResize);
    document.querySelector('.v-application--wrap').style.minHeight =
      'fit-content';
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.preventNavigation);
    window.removeEventListener('resize', this.onResize);
    document.querySelector('.v-application--wrap').style.minHeight = '100vh';
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  beforeRouteLeave(to, from, next) {
    if (this.closeOnboardingWithoutAlert) {
      next();
    } else {
      this.checkLeavingModal(next);
    }
  },
});
</script>
<style lang="sass">
@import "@/assets/style/main"
.onboarding-card
  width: 100%
.profileQuestionnaire
  width: 100%
  max-width: 940px
.dialogToolbar
  flex: 0 1 auto
</style>
